<template>
    <div id="Footer" class="r-center-flex" :class="!collapse ? '' : 'r-move-footer'">
        <div class="r-footer-space r-center-flex">
            <!-- Copyright Una4d. -->
        </div>
    </div>
</template>
<script>
export default {
  computed: {
    collapse(){
      return this.$store.getters['getCollapse']
    } 
  }
};
</script>
<style lang="scss">
.r-move-footer{
  width: 100% !important;
}
    #Footer{
        transition: all 0.2s ease;
        padding-top: 20px;
        background: #F6F6F6;
        position: fixed;
        right: 0px;
        width: calc(100% - 230px);
        bottom: 0px;
        .r-footer-space{
            background: #FFFFFF;
            box-shadow: inset 0px -1px 0px #E6E6E6;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #000000;
            width: 100%;
            justify-content: center;
            background: white;
            height: 45px;
            margin-top: auto;
        }
    }
</style>